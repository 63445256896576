// import autophp from '@/plugins/autophp.js'
// let sdb = new autophp()
import imageCompression from "browser-image-compression";
import axios from 'axios'
import md5 from 'md5'
import CryptoJS from 'crypto-js'
let apitoken = "borneojs";
let jawabanconfirm=false // confirm function 
var _promote; // confirm function promise
let auth = false; // pake auth atau tidak
let scrambles = true; //  laravel wajib selalu true
let popmsg = true; //  laravel wajib selalu true
let backend = "php"; //php, firebase, laravel
let table = "";
let id = "";
let errCount = 0;
// let url = false ? "https://api-kasir.officialapp.website" : `http://localhost:${backend!='laravel'?'/autos-kasir':'8000'}`;
// let url= true ? "https://apotek.officialapp.website" : `http://localhost:${backend!='laravel'?'/autos-kasir':'8000'}`;
// let url="https://apis.draesthetic.id" ;
let url="https://apis.draesthetic.id" ;
let urlBASE = url + "/apis.php";
let urlupload = url+'/upload1.php';
import firebase from 'firebase'
class autophp {
   table='';
   id="";
   collection(tables='',popmsgs=true) {
    popmsg=popmsgs
    this.table = tables;
    return this;
  }
    doc(ids) {
    if (ids) {
      // id = ids;
      this.id=ids;
    }
    return this;
  }
 
  async set(vdata,auths) {
    if(popmsg){
      loadingScreenAdd()
    }
    let fd = new FormData();
    // vdata.scramble = scrambles
    let keys = Object.keys(vdata)
    keys.forEach(e => {
      if (typeof vdata[e] == 'string') {
        vdata[e] = vdata[e].replace(/'/g, "\\'");
        vdata[e] = vdata[e].replace(/"/g, `\"`);
      }
    })
    // if (id != "") {
    //     vdata.id = id;
    // }
    if(auths){
      auth=auths
    }
    if (auth) {
      fd.append('header', localStorage.getItem('auth._token.local'))
      fd.append('key-h',localStorage.getItem('auth._token.local'));
    }
    // auth
    let setoken = await verifyEnc()
    fd.append("api-token", scramble(apitoken));
    fd.append('se-token',setoken);
    fd.append('5e19fb19a0aa66a0', scramble(auth.toString()))
    // fd.append('authentication', "Bearer E2-azzadoDS02389SAkas?29!asdbi3d$SIDsaiH#asjknku-JKNjkbduawdiasb-?ioh893798mskjaksjdnwiu=idbasid0asjudia" + "cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok-vyRxzUy0owj72EhAdz94kIRIMf4v4lHoFNF0tri_nmqIu4TfL8");
    // fd.append('Authorization', '"cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok"')
    // table
    fd.append("t-bl", scramble(this.table));
    fd.append("backend", scramble(backend));
    if (scrambles) {
      fd.append('scramble', scrambles);
      fd.append("data", scramble(JSON.stringify(vdata)));
    } else {
      fd.append("data", JSON.stringify(vdata));
    }
    // type
    fd.append("key-z-token", scramble("set"));
    fd.append("key", getKey(vdata));
    setTimeout(() => {
      
    }, 4000);
    function getKey(data) {
      return Object.keys(data);
    }
    return await axios.post(backend!='laravel'?urlBASE:url+'/api/set/data', fd).then(res => {
      if(popmsg){
        popupMsg('Proses Data Berhasil!','bg-green-400')
        loadingScreenRemove()
      }
      return res.data;
    }).catch(err => {
      if(popmsg){
        popupMsg('Error Proses Gagal!','bg-red-600')
        loadingScreenRemove()
      }
      errCount++;
      if (errCount < 2) {
        this.set(vdata)
      }
    })
  }
  async get(vdata, vdata2,auths) {
    let fd = new FormData();
    // vdata.scramble = scrambles
    // table
    fd.append("t-bl", scramble(this.table));
    fd.append("backend", scramble(backend));
    // auth
        let setoken = await verifyEnc()
    fd.append("api-token", scramble(apitoken));
    fd.append('se-token',setoken);
    // fd.append('authentication', "Bearer E2-azzadoDS02389SAkas?29!asdbi3d$SIDsaiH#asjknku-JKNjkbduawdiasb-?ioh893798mskjaksjdnwiu=idbasid0asjudia" + "cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok-vyRxzUy0owj72EhAdz94kIRIMf4v4lHoFNF0tri_nmqIu4TfL8");
    // fd.append('Authorization', '"cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok"')
    
    if(auths){
      auth=auths
    }
    if (auth) {
      fd.append('header', localStorage.getItem('auth._token.local'))
      fd.append('key-h',localStorage.getItem('auth._token.local'));
    }
    fd.append('5e19fb19a0aa66a0', scramble(auth.toString()))
    // select
    if (scrambles) {
      fd.append('scramble', scrambles);
      // SELECT
      fd.append("sd28(#092/sd", scramble(vdata));
      fd.append("23*(kdj", scramble(vdata2));
    } else {
      fd.append("sd28(#092/sd", vdata);
      fd.append("23*(kdj", vdata2);
    }
    if (this.id.length != "") {
      fd.append("id", `${this.id}`);
    }
    // type
    fd.append("key-z-token", scramble("get"));
    return await axios.post(backend!='laravel'?urlBASE:url+'/api/get/data', fd).then(res => {
      return res.data;
    }).catch(err => {
      if(popmsg){
        popupMsg('Error Proses Gagal!','bg-red-600')
      }
    })
  }
  async delete(auths,vdata) {
    let fd = new FormData();
    if (scrambles) {
      fd.append('scramble', scrambles);
    }
    if(auths){
      auth=auths
    }
    if (auth) {
      fd.append('header', localStorage.getItem('auth._token.local'))
      fd.append('key-h',localStorage.getItem('auth._token.local'));
    }
    // table
    fd.append("t-bl", scramble(this.table));
    // auth
        let setoken = await verifyEnc()
    fd.append("api-token", scramble(apitoken));
    fd.append('se-token',setoken);
    fd.append('5e19fb19a0aa66a0', scramble(auth.toString()))
    // fd.append('authentication', "Bearer E2-azzadoDS02389SAkas?29!asdbi3d$SIDsaiH#asjknku-JKNjkbduawdiasb-?ioh893798mskjaksjdnwiu=idbasid0asjudia" + "cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok-vyRxzUy0owj72EhAdz94kIRIMf4v4lHoFNF0tri_nmqIu4TfL8");
    // fd.append('Authorization', '"cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok"')
    fd.append("backend", scramble(backend));
    // auth
    fd.append("sd28(#092/sd", vdata);
    if (this.id.length != "") {
      fd.append("id", `${this.id}`);
    }
    // type
    fd.append("key-z-token", scramble("delete"));
      if(popmsg){
        popupMsg('Delete Data Berhasil!','bg-red-400')
      }
    return await axios.post(backend!='laravel'?urlBASE:url+'/api/delete/data', fd).then(res => {
      return res.data;
    }).catch(err => {
      if(popmsg){
        popupMsg('Error Proses Gagal!','bg-red-600')
      }
    })
  }
  async select(vdata,auths) {
    let fd = new FormData();
    // fd.append('authentication', "Bearer E2-azzadoDS02389SAkas?29!asdbi3d$SIDsaiH#asjknku-JKNjkbduawdiasb-?ioh893798mskjaksjdnwiu=idbasid0asjudia" + "cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok-vyRxzUy0owj72EhAdz94kIRIMf4v4lHoFNF0tri_nmqIu4TfL8");
        let setoken = await verifyEnc()
    fd.append('se-token',setoken);
    // fd.append('Authorization', '"cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok"')
    
    if(auths){
      auth=auths
    }
    if (auth) {
      fd.append('header', localStorage.getItem('auth._token.local'))
      fd.append('key-h',localStorage.getItem('auth._token.local'));
    }
    // table
    fd.append("t-bl", scramble(this.table));
    // auth
    fd.append("api-token", scramble(apitoken));
    fd.append('5e19fb19a0aa66a0', scramble(auth.toString()))
    fd.append("backend", scramble(backend));
    if (scrambles) {
      fd.append('scramble', scrambles);
      // SELECT
      fd.append("sd28(#092/sd", scramble(vdata));
    } else {
      fd.append("sd28(#092/sd", vdata);
    }
    if (this.id.length != "") {
      fd.append("id", `${this.id}`);
    }
    // type
    fd.append("key-z-token", scramble('select'));
    return await axios.post(backend!='laravel'?urlBASE:url+'/api/select/data', fd).then(res => {
      // console.log(res)
      return res.data;
    }).catch(err => {
      // console.log(err)
      if(popmsg){
        popupMsg('Error Proses Gagal!','bg-red-600')
      }
    })
  }
  async login2(vdata,store,router,routes){
    let fd = new FormData();
    let setoken = await verifyEnc()
    fd.append('se-token',setoken);
    fd.append("api-token", scramble(apitoken));
    fd.append('token-u',scramble(vdata.username));
    fd.append('token-p',scramble(vdata.password));
    // return await axios.post(url+'/auth/login.php',fd).then(res=>{
      return await axios.post(backend!='laravel'?url + '/auth/login.php':url+'/api/login/data',fd).then(res=>{
      localStorage.setItem('auth._token.local', scramble(res.data.token));
      localStorage.setItem('auth.local', scramble(res.data.token));
      let fd2 = new FormData();
      fd2.append("api-token", scramble(apitoken));
      fd2.append('se-token',setoken);
      fd2.append('key-h',localStorage.getItem('auth._token.local'));
      fd2.append("key-z-token", scramble('getuser'));
      // axios.post(url + '/apis.php', fd2, {
        axios.post(backend!='laravel'?url + '/apis.php':url+'/api/getuser/data', fd2, {
          headers: {
            Authorization: localStorage.getItem('auth._token.local')
          }
        })
        .then(res => {
          this.Oauth = true
          if(store){
            store.commit("changeUsers", res.data);
          }
          localStorage.setItem("users", JSON.stringify(res.data));
          if(routes){
            if(router){
              router.push(routes)
            }
          }else{

          }
        });
    }).catch(err=>{
      if(popmsg){
        popupMsg('Username atau Password salah!','bg-red-600')
      }
    })
  }
  async register(vdata){
    let fd = new FormData();
        let setoken = await verifyEnc()
    fd.append('se-token',setoken);
    // fd.append('authentication', "Bearer E2-azzadoDS02389SAkas?29!asdbi3d$SIDsaiH#asjknku-JKNjkbduawdiasb-?ioh893798mskjaksjdnwiu=idbasid0asjudia" + "cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok-vyRxzUy0owj72EhAdz94kIRIMf4v4lHoFNF0tri_nmqIu4TfL8");
    // fd.append('Authorization', '"cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok"')
    fd.append("api-token", scramble(apitoken));
    fd.append('token-u',scramble(vdata.username));
    fd.append('token-p',scramble(vdata.password));
    // return await axios.post(url+'/auth/register.php',fd).then(res=>{
      return await axios.post(backend!='laravel'?url + '/auth/register.php':url+'/api/register/data',fd).then(res=>{
      return res.data
    })
  }
  async ceklogin(store, router) { // CEK LOGIN BACKEND PHP
    let fd2 = new FormData();
    let setoken = await verifyEnc()
    fd2.append("api-token", scramble(apitoken));
    fd2.append('se-token',setoken);
    fd2.append("backend", scramble(backend));
    fd2.append("key-z-token", scramble('getuser'));
    fd2.append('key-h',localStorage.getItem('auth._token.local'));
      // return await axios.post(url + "/apis.php", fd2, {
        return await axios.post(backend!='laravel'?url + '/apis.php':url+'/api/getuser/data', fd2, {
        headers: {
          Authorization: localStorage.getItem('auth._token.local')
        }
      })
      .then(res => {
        // if(res.data.indexOf('error')!=-1){
        //   router.push('/')
        // }else{
      if(typeof res.data=='object'){
            if(store){
              store.commit("changeUsers", res.data);
            }
            localStorage.setItem("users", JSON.stringify(res.data));
            return res
        } else {
          throw Error;
        }

        // }
      }).catch(err => {
        // alert('y')
        throw Error;
      })
  }
  logout(that,path) {
    console.log(that)
     that.$store.state.user = false
     that.$store.state.users = false
    //  that.$store.state.data = {
    //    listfavorites:[]
    //  }
     localStorage.setItem('expireDate', null)
     localStorage.setItem('auth._token.local', false)
     localStorage.setItem('auth.local', false)
     localStorage.setItem('data-persist', null)
    firebase
      .auth()
      .signOut()
      .then(() => {
        that.$store.state.isAuth = false
        window.location.reload();
        that.$router.push(path)
      });
  }
  async getuser(store) {
    let fd2 = new FormData();
        let setoken = await verifyEnc()
    fd2.append('se-token',setoken);
    // fd2.append('authentication', "Bearer E2-azzadoDS02389SAkas?29!asdbi3d$SIDsaiH#asjknku-JKNjkbduawdiasb-?ioh893798mskjaksjdnwiu=idbasid0asjudia" + "cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok-vyRxzUy0owj72EhAdz94kIRIMf4v4lHoFNF0tri_nmqIu4TfL8");
    // fd2.append('Authorization', '"cPbt9I1_VB4:APA91bHrrUimOQ9QDOaTLUEWMHEGs8xhwCL5FYaGJhHccHYFuzlleb4RiH_7uQrFtaMXjqil9QKJhsvwod-ewMBok"')
    fd2.append("api-token", scramble(apitoken));
    fd2.append("backend", scramble(backend));
    fd2.append("key-z-token", scramble('getuser'));
    fd2.append('key-h',localStorage.getItem('auth._token.local'))
    return await axios
      .post(backend!='laravel'?url + '/apis.php':url+'/api/getuser/data', fd2, {
        headers: {
          Authorization: localStorage.getItem('auth._token.local')
        }
      })
      .then(res => {
        if(store){
          store.commit("changeUsers", res.data[0]);
        }
        localStorage.setItem("users", JSON.stringify(res.data[0]));
        return res.data[0]
      });
  }
  async upload(file) {
    let that = this;
    let el = file; // berikan id pada input file
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      let file = el.files[0];
      let fd = new FormData();
      // const compressedFile = await imageCompression(file, options);
      let setoken = await verifyEnc()
      fd.append("api-token", scramble(apitoken));
      fd.append('se-token',setoken);
      fd.append("file", file);
      fd.append('data', `select * from tbuser`) //database setting di server
      fd.append('secret', `dsdxxoi4#$(*#sdsaaada@#`) //database setting di server
      if (auth) {
        fd.append('header', localStorage.getItem('auth._token.local'))
        fd.append('key-h',localStorage.getItem('auth._token.local'));
      }
      // return await axios.post(urlupload, fd).then(res => {
      return await axios.post(backend!='laravel'?urlupload:url+'/api/upload/data', fd).then(res => {
        // let urlnya = backend != 'laravel' ? url+res.data : url+'/storage/'+res.data
        return res.data
        // "https://upload.egov.balangankab.go.id" + res.data;
      })
    } catch (error) {
      if(popmsg){
        popupMsg('Error Proses Gagal!','bg-red-600')
      }
    }
  }
  async upload2(file) {
    let that = this;
    // alert('hello')
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    }
    try {
      let fd = new FormData();
      const compressedFile = await imageCompression(file, options);
      // alert(JSON.stringify(compressedFile))
      console.log('compressFile',compressedFile)
      let setoken = await verifyEnc()
      fd.append("api-token", scramble(apitoken));
      fd.append('se-token',setoken);
      fd.append("file", compressedFile);
      fd.append('data', `select * from tbuser`) //database setting di server
      fd.append('secret', `dsdxxoi4#$(*#sdsaaada@#`) //database setting di server
      if (auth) {
        fd.append('header', localStorage.getItem('auth._token.local'))
        fd.append('key-h',localStorage.getItem('auth._token.local'));
      }
      // return await axios.post(urlupload, fd).then(res => {
      return await axios.post(url+'/upload2.php', fd).then(res => {
        // let urlnya = backend != 'laravel' ? url+res.data : url+'/storage/'+res.data
        return res.data
        // "https://upload.egov.balangankab.go.id" + res.data;
      })
    } catch (error) {
      if(popmsg){
        popupMsg('Error Proses Gagal!','bg-red-600')
      }
    }
  }
  // =========================================== FUNCTIONAL
  alert(text,color='bg-red-600'){
    popupMsg(text,color);
  }
  async confirm(text,color='bg-red-600'){
      await new Promise((resolve,reject)=>{
        confirmAdd(text,resolve);
      });
      confirmRemove();
      return jawabanconfirm;
  }
  loadingOn(){
    loadingScreenAdd();
  }
  loadingOff(){
    loadingScreenRemove();
  }
  scramble(datas){
    let data=scramble(datas);
    return data
  }
}


async function verifyEnc(){
  //  var CryptoJSAesJson = {
  //   stringify: function (cipherParams) {
  //       var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
  //       if (cipherParams.iv) j.iv = cipherParams.iv.toString();
  //       if (cipherParams.salt) j.s = cipherParams.salt.toString();
  //       return JSON.stringify(j);
  //   },
  //   parse: function (jsonStr) {
  //       var j = JSON.parse(jsonStr);
  //       var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
  //       if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv)
  //       if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s)
  //       return cipherParams;
  //   }
  // }
  // let sec 
  // try {
  //   sec= await axios.post(backend!='laravel'?url+'/auth/108starsofdestiny.php':url+'/api/get/jam',fd)
  // }
  // catch(err) {
  //     if(popmsg){
  //       popupMsg('Error Proses Gagal!','bg-red-600')
  //       loadingScreenRemove()
  //     }
  // }
  // let fd = new FormData()
  // var encrypted = CryptoJS.AES.encrypt(JSON.stringify(apitoken), sec.data.toString(), {format: CryptoJSAesJson}).toString();
  // console.log('encrypted',encrypted)
  var encrypted='kinonotabi';
  return encrypted
}

function popupMsg(txt,color){
  let div = document.createElement('div')
  div.setAttribute('id','popup-msg')
  div.setAttribute('style','position:fixed;top:30px;right:30px;z-index:10000;')
  div.setAttribute('class',`rounded-lg shadow ${color} text-white animated fadeInDown p-3`);
  div.addEventListener('click',(e)=>{
    console.log(e.target.remove())
  })
  div.innerHTML=txt;
  document.querySelector('body').appendChild(div);
  setTimeout(() => {
    document.querySelector('#popup-msg').classList.remove('fadeInDown');
    document.querySelector('#popup-msg').classList.add('fadeOutUp');
    setTimeout(() => {
      document.querySelector('#popup-msg').remove();
    }, 1000);
  }, 2000);
}

function loadingScreenAdd(txt,color){
  let div = document.createElement('div')
  let div2 = document.createElement('div')
  div.addEventListener('click',function(){
    setTimeout(() => {
      loadingScreenRemove();
      popupMsg('Refresh apabila loading terlalu lama dan data tidak terupdate!','bg-red-400');
    }, 500);
  })
  div.setAttribute('id','loadingScreen')
  div.setAttribute('style','position:fixed;top:0;left:0;z-index:10000;height:100vh;width:100vw;background:rgb(0,0,0,0.5)')
  div.setAttribute('class',` animated fadeIn d-flex justify-content-center align-items-center`);
  div2.setAttribute('id','loadingScreen2')
  div2.setAttribute('class',`text-xl text-white font-bold text-center italic lds-ripple`);
  div2.innerHTML='<div class=""></div><br><br><br><p class="text-center"> Loading </p>';
  div.appendChild(div2);
  document.querySelector('body').appendChild(div);
 
}
function loadingScreenRemove(){
    document.querySelector('#loadingScreen').classList.remove('fadeIn');
    document.querySelector('#loadingScreen').classList.add('fadeOut');
    setTimeout(() => {
      document.querySelector('#loadingScreen').remove();
    }, 500);
}
function jikayes(){
  alert('woke')
}
function jikano(){
  alert('no')
}

async function confirmAdd(txt,resolve,color){
  let div = document.createElement('div')
  let div2 = document.createElement('div')
  let divrow1 = document.createElement('div')
  let divrow2 = document.createElement('div')
  let divcol6p1 = document.createElement('div')
  let divcol4p1 = document.createElement('div')
  let divcol4p2 = document.createElement('div')
  let divcol4p3 = document.createElement('div')
  let buttonx = document.createElement('button')
  let buttonyes = document.createElement('button')
  let buttonno = document.createElement('button')
  let ptext = document.createElement('p')
  let garis = document.createElement('hr')
  div.setAttribute('id','confirmScreen')
  div.setAttribute('style','position:fixed;top:0;left:0;z-index:10000;height:100vh;width:100vw;background:rgb(0,0,0,0.5)')
  div.setAttribute('class',` animated fadeIn d-flex justify-content-center align-items-center`);
  div2.setAttribute('id','confirmScreen2')
  div2.setAttribute('class',``);
  divrow1.setAttribute('class','row justify-content-center')
  divrow1.setAttribute('style','width:100vw;')
  divrow2.setAttribute('class','row justify-content-between')
  divcol6p1.setAttribute('class','animate__animated animate__backInDown bg-white rounded-lg p-3 shadow col-10 col-sm-6')
  divcol6p1.setAttribute('style','min-height:35h;')
  divcol4p1.setAttribute('class','offset-2 col-4')
  divcol4p2.setAttribute('class','col-4')
  divcol4p3.setAttribute('class','col-2')
  buttonx.setAttribute('class','btn btn-sm btn-dark  float-right')
  buttonx.innerHTML='x'
  buttonyes.setAttribute('class','btn btn-sm btn-success  btn-block')
  buttonyes.innerHTML='<span class="typcn typcn-tick"></span> Yes'
  buttonno.setAttribute('class','btn btn-sm btn-danger  btn-block')
  buttonno.innerHTML='<span class="typcn typcn-cancel"></span> No'
  ptext.setAttribute('class','font-semibold text-xl')
  ptext.innerHTML=`${txt}`;
  divcol4p2.appendChild(buttonno);
  divcol4p1.appendChild(buttonyes);
  divrow2.appendChild(divcol4p1);
  divrow2.appendChild(divcol4p2);
  divrow2.appendChild(divcol4p3);
  divcol6p1.appendChild(buttonx);
  divcol6p1.appendChild(ptext);
  divcol6p1.appendChild(garis);
  divcol6p1.appendChild(divrow2);
  divrow1.appendChild(divcol6p1);
  div2.appendChild(divrow1)
  div.appendChild(div2);
  buttonx.addEventListener('click',function(){
    jawabanconfirm=false;
    resolve('no');
  })
  buttonyes.addEventListener('click',function(){
    jawabanconfirm=true;
    resolve('yes');
  })
  buttonno.addEventListener('click',function(){
    jawabanconfirm=false;
    resolve('no');
  })
  document.querySelector('body').appendChild(div);
}
function confirmRemove(){
    document.querySelector('#confirmScreen').classList.remove('fadeIn');
    document.querySelector('#confirmScreen').classList.add('fadeOut');
    setTimeout(() => {
      document.querySelector('#confirmScreen').remove();
    }, 1000);
}

function scramble(teks) {
  let char = "";
  let hasil = "";
  for (var i = 0; i < teks.length; i++) {
    char = "";
    char = teks.charAt(i);
    if (char == "a") {
      char = char.replace("a", "x");
    } else if (char == "b") {
      char = char.replace("b", "v");
    } else if (char == "c") {
      char = char.replace("c", "u");
    } else if (char == "d") {
      char = char.replace("d", "w");
    } else if (char == "e") {
      char = char.replace("e", "y");
    } else if (char == "f") {
      char = char.replace("f", "z");
    } else if (char == "g") {
      char = char.replace("g", "t");
    } else if (char == "h") {
      char = char.replace("h", "s");
    } else if (char == "i") {
      char = char.replace("i", "r");
    } else if (char == "j") {
      char = char.replace("j", "q");
    } else if (char == "k") {
      char = char.replace("k", "p");
    } else if (char == "l") {
      char = char.replace("l", "o");
    } else if (char == "m") {
      char = char.replace("m", "n");
    } else if (char == "z") {
      char = char.replace("z", "f");
    } else if (char == "y") {
      char = char.replace("y", "e");
    } else if (char == "x") {
      char = char.replace("x", "a");
    } else if (char == "w") {
      char = char.replace("w", "d");
    } else if (char == "v") {
      char = char.replace("v", "b");
    } else if (char == "u") {
      char = char.replace("u", "c");
    } else if (char == "t") {
      char = char.replace("t", "g");
    } else if (char == "s") {
      char = char.replace("s", "h");
    } else if (char == "r") {
      char = char.replace("r", "i");
    } else if (char == "q") {
      char = char.replace("q", "j");
    } else if (char == "p") {
      char = char.replace("p", "k");
    } else if (char == "o") {
      char = char.replace("o", "l");
    } else if (char == "n") {
      char = char.replace("n", "m");
    } else if (char == "1") {
      char = char.replace("1", "0");
    } else if (char == "2") {
      char = char.replace("2", "8");
    } else if (char == "3") {
      char = char.replace("3", "9");
    } else if (char == "4") {
      char = char.replace("4", "7");
    } else if (char == "5") {
      char = char.replace("5", "6");
    } else if (char == "0") {
      char = char.replace("0", "1");
    } else if (char == "8") {
      char = char.replace("8", "2");
    } else if (char == "9") {
      char = char.replace("9", "3");
    } else if (char == "7") {
      char = char.replace("7", "4");
    } else if (char == "6") {
      char = char.replace("6", "5");
    } else if (char == " ") {
      char = char.replace(" ", "_");
    } else if (char == "_") {
      char = char.replace("_", " ");
    } else if (char == "*") {
      char = char.replace("*", "/");
    } else if (char == "/") {
      char = char.replace("/", "*");
    } else if (char == ",") {
      char = char.replace(",", "^");
    } else if (char == "^") {
      char = char.replace("^", ",");
    }
    hasil = hasil + char;
  }
  return hasil;
}

let style = document.createElement('style')
style.innerHTML=`
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
`;
document.head.appendChild(style);

export default autophp